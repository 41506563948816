<template>
    <styled-slideout
        :value="value"
        create-on-open
        @input="$emit('input', $event)">
        <div class="pa-5">
            <div class="description">
                <div>
                    <h2>Review Inventory Sample</h2>
                    <p>
                        Please review the inventory sample below to check
                        for overlays.  If you find that a large quantity of
                        vehicles have overlays please close this tool and use the
                        email button to contact the feed provider.
                    </p>
                </div>
                <action-button
                    icon="refresh"
                    :disabled="loading"
                    @click="getInventory">
                    Reload
                </action-button>
            </div>
            <div
                v-if="loading"
                class="text-xs-center mt-5">
                <styled-loader
                    size="25"
                    label="Loading inventory sample..." />
            </div>
            <div
                v-else
                class="inventory">
                <a
                    v-for="item in formattedInventory"
                    :key="item.id"
                    :href="item.url"
                    target="_blank"
                    class="inventory-item">
                    <img :src="item.image">
                    <p>
                        {{ item.name }}
                    </p>
                </a>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledLoader from '@/components/globals/StyledLoader';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { mapState } from 'vuex';

export default {
    components: {
        StyledSlideout,
        StyledLoader,
        ActionButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            inventory: [],
            error: ''
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer
        }),
        formattedInventory() {
            return this.inventory
                .filter(item => (
                    item.dealer_vehicle?.data?.[0]?.vdp_url
                    && item.images?.data?.[0]?.original_url
                ))
                .map(item => ({
                    id: item.id,
                    name: item.display_name,
                    url: item.dealer_vehicle?.data?.[0]?.vdp_url ?? '',
                    image: item.images?.data?.[0]?.original_url ?? '',
                }))
                .slice(0, 6);
            }
    },
    mounted() {
        this.getInventory();
    },
    methods: {
        async getInventory() {
            try {
                this.loading = true;
                const response = await this.$apiRepository.getDealerInventory(this.dealer.id);
                this.inventory = response.data.data;
            } catch(error) {
                this.error = 'There was an error pulling inventory.  Please try again or contact support';
                console.error('Error retrieving inventory', error.response ?? error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.description {
    display: flex;
    align-items: center;
    .action-button {
        margin-left: 40px;
    }
}
.inventory {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -10px 0;
}
.inventory-item {
    width: 50%;
    padding: 10px;
    text-align: center;
    img {
        max-width: 100%;
        height: auto;
    }
    p {
        font-weight: 700;
    }
}
</style>