<template>
    <div>
        <email-provider-slideout
            v-model="emailProviderSlideoutOpen" />
        <sample-inventory-slideout
            v-model="sampleInventorySlideoutOpen" />

        <styled-callout
            v-if="dealerInventoryType == 'feed_inventory' && !feedRdiConfigured"
            class="layout align-center mb-4">
            <div class="flex shrink mr-3">
                <status-icon
                    value="warning"
                    size="25" />
            </div>
            <div class="flex">
                <p>
                    You need to finish configuring your feed inventory
                    to continue with these steps.
                    <br>
                    <a
                        href="#"
                        @click.prevent="goToStepByKey('feed_inventory')">
                        <span>
                            Click here to return to the feed inventory setup
                            step and finish setting up your feed.
                        </span>
                    </a>
                </p>
            </div>
        </styled-callout>

        <styled-callout
            v-else-if="dealer.vehicle_count === 0"
            class="layout align-center mb-4">
            <div class="flex shrink mr-3">
                <status-icon
                    value="warning"
                    size="25" />
            </div>
            <div class="flex">
                <p>
                    This account must have inventory to complete these steps.
                    Please return to the inventory steps and ensure that
                    there are no remaining tasks to complete.  If everything is
                    setup correctly you may just need wait until inventory
                    is fully processed.
                </p>
            </div>
            <action-button
                icon="refresh"
                :loading="dealerLoading"
                label="Recheck"
                @click="updateCurrentDealer" />
        </styled-callout>

        <styled-callout
            v-else
            class="layout align-center mb-4">
            <div class="flex shrink mr-3">
                <status-icon
                    value="warning"
                    size="25" />
            </div>
            <div class="flex">
                <p>
                    This account has inventory but you may need to wait
                    up to 48 hours for the listings to be sent to Google.
                    This onboarding will remain active until you've completed
                    this process so you can return to this step at any time.
                    <a
                        href="https://support.buyerbridge.com/knowledge/google-vehicle-listings"
                        target="_blank">
                        <span>Click here for instructions</span>
                    </a>
                    on verifying that the listings have been sent to Google.
                </p>
            </div>
        </styled-callout>

        <div class="mb-5">
            <h2>Google Vehicle Listings Checklist</h2>
            <p>
                You're almost there!  To ensure that BuyerBridge
                is able to send listings to Google please verify
                that the tasks below have been completed:
            </p>
        </div>

        <div
            class="checklist-items"
            :class="{
                'disabled': disabled
            }">
            <!-- IMAGE OVERLAYS START -->
            <div
                v-if="dealerInventoryType === 'feed_inventory'"
                class="checklist-item"
                :class="{
                    'complete': overlaysVerified
                }">
                <styled-checkbox
                    :checked.sync="overlaysVerified"
                    checkmark-scale="1.3"
                    square
                    color="green" />
                <div class="checklist-item-content">
                    <h3>Images Do Not Have Overlays</h3>
                    <p>
                        Google will <strong>not list vehicles with image overlays</strong>.
                        Please review some sample inventory using the button to the right
                        to confirm that there are no overlays.  If you see overlays, you
                        can typically request that they be removed by the feed provider.
                        To begin this process, you can use our email template by clicking the
                        email button to the right.
                    </p>
                </div>
                <div class="checklist-item-buttons">
                    <action-button
                        color="#00A4EB"
                        width="35"
                        height="35"
                        icon="car"
                        @click="sampleInventorySlideoutOpen = true">
                        <div class="dark-word">
                            View Sample<br> Inventory
                        </div>
                    </action-button>
                    <action-button
                        color="#00A4EB"
                        width="35"
                        height="35"
                        icon="mail"
                        @click="emailProviderSlideoutOpen = true">
                        <div class="dark-word">
                            Send Email<br>to Provider
                        </div>
                    </action-button>
                </div>
            </div>
            <!-- IMAGE OVERLAYS END -->

            <!-- LISTINGS VERIFIED START -->
            <div
                class="checklist-item"
                :class="{
                    'complete': listingsVerified
                }">
                <styled-checkbox
                    :checked.sync="listingsVerified"
                    checkmark-scale="1.3"
                    color="green"
                    square />
                <div class="checklist-item-content">
                    <h3>Vehicle Inventory Sent to Google Within 48 Hours</h3>
                    <p>
                        This account's inventory is currently being sent to
                        Google, but it may take up to 48 hours for them to
                        receive and process the inventory. Once 48 hours have
                        passed, you can check this item off and proceed to the
                        next step!
                    </p>
                </div>
                <div class="checklist-item-buttons">
                    <action-button
                        color="#00A4EB"
                        width="35"
                        height="35"
                        href="https://support.buyerbridge.com/knowledge/google-vehicle-listings"
                        target="_blank"
                        icon="book">
                        <div class="dark-word">
                            How to Verify<br>
                            Listings
                        </div>
                    </action-button>
                </div>
            </div>
            <!-- LISTINGS VERIFIED END -->

            <!-- PROVIDER VERIFIED START -->
            <div
                class="checklist-item"
                :class="{
                    'complete': providerVerified
                }">
                <styled-checkbox
                    :checked.sync="providerVerified"
                    checkmark-scale="1.3"
                    color="green"
                    square />
                <div class="checklist-item-content">
                    <h3>Data Provider is Set to "BuyerConnector"</h3>
                    <p>
                        If this account has an existing Google Vehicle Listing
                        provider then you'll need to set the new BuyerConnector
                        feed as the "Primary" feed in this account's Google Business
                        Profile settings once inventory has fully processed (48 hours
                        after setup). Click the button to the right for full instructions.
                    </p>
                    <p>
                        Once BuyerConnector is set as the Primary feed or you're certain
                        there is no existing feed provider you can mark this complete!
                    </p>
                </div>
                <div class="checklist-item-buttons">
                    <action-button
                        color="#00A4EB"
                        width="35"
                        height="35"
                        href="https://support.buyerbridge.com/knowledge/change-preferred-provider-google-vehicle-listings"
                        target="_blank"
                        icon="book">
                        <div class="dark-word">
                            Read our<br>guide
                        </div>
                    </action-button>
                </div>
            </div>
            <!-- PROVIDER VERIFIED END -->
        </div>


        <div class="layout align-center">
            <back-button @click="goToPreviousStep" />
            <styled-button
                class="ml-auto"
                :disabled="!valid"
                @click="goToNextStep">
                Continue
            </styled-button>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledCallout from '@/components/globals/StyledCallout';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import StyledButton from '@/components/globals/StyledButton';
import BackButton from '@/components/globals/BackButton';
import EmailProviderSlideout from './EmailProviderSlideout';
import SampleInventorySlideout from './SampleInventorySlideout';
import { mapActions, mapGetters, mapState } from 'vuex';
import {

} from '@/helpers/globals';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';

export default {
    name: 'GoogleBusinessProfile',
    components: {
        StatusIcon,
        StyledCallout,
        StyledCheckbox,
        ActionButton,
        StyledButton,
        BackButton,
        EmailProviderSlideout,
        SampleInventorySlideout,
    },
    data() {
        return {
            loadingIdentityUpdate: false,
            placeId: null,
            errorMessage: '',
            emailProviderSlideoutOpen: false,
            sampleInventorySlideoutOpen: false
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
            dealerLoading: (state) => state.dealer.dealerLoading,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerFeaturesComplete',
            'dealerInventoryType',
            'dealerFeedRdi'
        ]),
        feedRdiConfigured() {
            if (!this.dealerFeedRdi) {
                return false;
            }

            return (this.dealerFeedRdi.id_string.length && this.dealerFeedRdi.id_string !== '-- temporary --');
        },
        listingsVerified: {
            get() {
                return this.onboardingData.googleMarketplaceListingsVerified ?? false;
            },
            set(verified) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    googleMarketplaceListingsVerified: verified
                });
            }
        },
        providerVerified: {
            get() {
                return this.onboardingData.googleMarketplaceProviderVerified ?? false;
            },
            set(verified) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    googleMarketplaceProviderVerified: verified
                });
            }
        },
        overlaysVerified: {
            get() {
                return this.onboardingData.googleMarketplaceOverlaysVerified ?? false;
            },
            set(verified) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    googleMarketplaceOverlaysVerified: verified
                });
            }
        },
        disabled() {
            if (
                this.dealerInventoryType == 'feed_inventory'
                && !this.feedRdiConfigured
            ) {
                return true;
            }

            return this.dealer.vehicle_count === 0;
        },
        valid() {
            return (
                this.providerVerified &&
                (
                    this.dealerInventoryType === 'feed_inventory'
                        ? this.overlaysVerified
                        : true
                )
            );
        }

    },
    methods: {
        ...mapActions([
            'goToPreviousStep',
            'goToNextStep',
            'updateCurrentDealer',
            'goToStepByKey'
        ])
    }
};
</script>

<style lang="scss" scoped>
.checklist-items {
    margin: 0 auto;
    padding: 0 40px;
    margin-bottom: 80px;
    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    &.complete {
        h3 {
            text-decoration: line-through;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.checklist-item-content {
    margin: 0 40px;
    width: 65%;
}
.checklist-item-buttons {
    display: flex;
    justify-content: flex-start;
}
.dark-word {
    min-width: 80px;
}
.action-button {
    margin: 0 10px;
}
</style>