<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h3>Request for Overlay Removal</h3>
            <p>
                To begin the process of removing overlays, send the message
                below to {{ feedProvider.name }} and they should be able to
                accommodate the request or connect you with someone who can!
            </p>
            <copy-text-area
                hide-download
                :email-to="!!feedProvider.support_email ? feedProvider.support_email : ''"
                :email-subject="`BuyerBridge Overlay Removal Request: ${dealer.name}`"
                class="mb-4">
                <p>
                    Hello {{ feedProvider.name }}!
                </p>
                <p>
                    In order to optimize our inventory for BuyerBridge we'd like
                    to remove image overlays from all vehicle images (e.g. enabling
                    "clean" images).
                </p>
                <p>
                    Please update {{ dealer.name }}'s export to BuyerBridge to use
                    clean images and, if possible, manually push the feed.
                </p>
                <p>
                    Detail on this account are below:
                </p>
                <p>
                    <strong>Dealer:</strong> {{ dealer.name }}<br>
                    <strong>Website:</strong> {{ dealerHostname }}<br>
                    <strong>Phone:</strong> {{ dealer.phone }}<br>
                    <strong>Address:</strong> {{ dealerAddressString }}
                </p>
                <p>
                    Thanks!<br>
                    The {{ dealer.agency.name }} Team
                </p>
            </copy-text-area>
        </div>
    </styled-slideout>
</template>

<script>
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        CopyTextArea,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
        }),
        ...mapGetters([
            'dealerFeedRdi',
            'dealerAddressString',
            'dealerHostname'
        ]),
        feedProvider() {
            return this.dealerFeedRdi.vehicle_data_source;
        }
    }
};
</script>